<template>
  <div v-if="!gameOver" class="play-game-container">
    <div class="play-game-content">
      <div v-if="isLoading" class="loading-container">
        <div class="spinner"></div>
        <p class="loading-text">Loading Today's Game</p>
      </div>
      
      <template v-else>
        <div class="button-container">
          <button @click="showFirstGameModal = true" class="btn btn-success rules-btn">How to Play</button>
          <button @click="goToLeaderboard" class="btn btn-success rules-btn">Leaderboard</button>
          <button v-if="!user" @click="showLoginRegister" class="btn btn-success rules-btn">Login/Register</button>
          <button v-if="user" @click="goToProfile" class="btn btn-success rules-btn">Profile</button>
        </div>
        <div v-if="!gameOver" class="play-game">
          <!-- If user has already played today -->
          <div v-if="alreadyPlayedToday" class="already-played-message">
            <h2>New game in {{ countdownToMidnight }}</h2>
          </div>

          <!-- Display saved game state in non-interactive mode -->
          <div v-if="alreadyPlayedToday && user && gameProgressExists" class="non-interactive-game">
            <div class="question-card">
              <h3>Today's Game</h3>
              <h2>{{ game?.questionText }}</h2>
              <p class="question-short-description">{{ game?.questionShortDescription }}</p>
              <h4>You scored {{ currentScore }} points today</h4>
              <button @click="shareScore" class="btn small-green-btn">Share Score</button>
              <a :href="feedbackEmailLink" class="feedback-link">Feedback?</a>
            </div>
            <div class="answers">
              <div v-for="(answer, index) in shuffledAnswers" :key="index" class="answer-wrapper">
                <button 
                  :disabled="true" 
                  :class="['answer-btn', answer.clicked ? (answer.correct ? 'correct' : 'bomb') : '', answer.animationClass]"
                >
                  <span class="answer-text">{{ answer.text }}</span>
                  <span v-if="answer.clicked" class="answer-result">
                    <template v-if="answer.correct">{{ answer.display }}</template>
                    <template v-else>
                      <i class="fas fa-bomb"></i>
                      <SmallBombAnimation :show="answer.showBombAnimation" @animation-end="answer.showBombAnimation = false" />
                    </template>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <!-- If user is logged in and has player role, show the game -->
          <div v-else-if="(user && isPlayer && !alreadyPlayedToday) || (!user && !alreadyPlayedToday)">
            <div class="question-card">
              <h3>Today's Game</h3>
              <h2>{{ game?.questionText }}</h2>
              <p class="question-short-description">{{ game?.questionShortDescription }}</p>
              <button 
                v-if="currentScore > 0"
                @click="showConfirmationForBank"
                class="btn btn-success bank-btn" 
              >
                <span>Stop and Bank {{ currentScore }} Points</span>
              </button>
              <!-- Move the feedback link here -->
              <a :href="feedbackEmailLink" class="feedback-link">Feedback?</a>
            </div>
            <p class="instruction">Tap and hold an answer to select it</p>
            <div class="answers">
              <div v-for="(answer, index) in shuffledAnswers" :key="index" class="answer-wrapper">
                <button 
                  :disabled="answer.clicked || disableAllAnswers" 
                  @mousedown="startHold(index)"
                  @mouseup="cancelHold(index)"
                  @mouseleave="cancelHold(index)"
                  @touchstart="onTouchStart($event); startHold(index)"
                  @touchmove="onTouchMove($event)"
                  @touchend="cancelHold(index)"
                  :class="['answer-btn', answer.clicked ? (answer.correct ? 'correct' : 'bomb') : '', answer.animationClass]"
                >
                  <div v-if="!answer.clicked" class="fill-overlay" :style="{ width: answer.holdProgress + '%' }"></div>
                  <span class="answer-text">{{ answer.text }}</span>
                  <span v-if="answer.clicked" class="answer-result">
                    <template v-if="answer.correct">{{ answer.display }}</template>
                    <template v-else>
                      <i class="fas fa-bomb"></i>
                      <SmallBombAnimation :show="answer.showBombAnimation" @animation-end="answer.showBombAnimation = false" />
                    </template>
                  </span>
                </button>
              </div>
            </div>        
          </div>

          <!-- First Game Modal -->
          <div v-if="showFirstGameModal" class="modal-overlay">
            <div class="modal-content">
              <h2>Welcome to Get7!</h2>
              <p>Here's how to play:</p>
              <ul>
                <li>Find 7 correct answers in the category.</li>
                <li>Avoid 3 incorrect answers (bombs).</li>
                <li>Correct answers start at 100 points each.</li>
                <li>The first two bombs you hit will each halve the value of all answers.</li>
                <li>The third bomb will end the game and you will lose all your points.</li>
                <li>Bank your points anytime to end the game and secure your score.</li>
                <li>Play daily to climb the weekly leaderboard!</li>
              </ul>
              <button @click="closeFirstGameModal" class="btn btn-primary">Got it!</button>
            </div>
          </div>

          <!-- Game Over Modal -->
          <div v-if="showGameOverModal" class="modal-overlay">
            <div class="modal-content">
              <h2>Game Over!</h2>
              <p>You hit 3 bombs. Your final score is 0.</p>
              <button @click="closeGameOverModal" class="btn btn-primary">Close</button>
            </div>
          </div>

          <BombAnimation :show="showBombAnimation" />
          <FireworksAnimation :show="showFireworks" @animation-end="showFireworks = false" />
        </div>
      </template>
    </div>
  </div>

  <div v-if="gameOver" class="game-over-wrapper">
    <GameOverView 
      :currentGameScore="currentScore" 
      :questionText="game?.questionText" 
      :correctAnswers="game?.correctAnswers" 
      :bombs="game?.incorrectAnswers" 
      :bombsHit="bombCount" 
      :gameId="game?.id"
    />
  </div>

  <!-- Last Week's Leaderboard Modal -->
  <div v-if="showLastWeekModal" class="modal-overlay">
    <div class="modal-content">
      <span class="close" @click="closeLastWeekModal">&times;</span>
      <h3>Last Week's Winners</h3>
      <div v-if="lastWeekUserRank !== null">
        <p>Your Rank: {{ lastWeekUserRank }}</p>
      </div>
      <div>
        <h4>Top 3 Finishers</h4>
        <div class="top-finishers">
          <div v-for="(player, index) in lastWeekTop3" :key="player.uid" class="finisher-row">
            <i :class="['fas', getMedalIcon(index)]"></i>
            <span class="player-name">{{ player.username }}</span>
          </div>
        </div>
      </div>
      <!--
      <p>A new weekly leaderboard starts today - share Get7 with your friends now!</p>
      <button @click="shareGame" class="btn btn-primary rules-btn">Share</button>
    -->
      <h3>Enjoying Get7?</h3> 
      <p>Please consider sharing the game with your friends!</p>
      <button @click="shareGame" class="btn btn-primary rules-btn--large">Share</button>
      <button @click="closeLastWeekModal" class="btn btn-primary rules-btn--large">Close</button>

    </div>
  </div>

  <!-- Add this confirmation modal -->
  <div v-if="showConfirmationModal" class="modal-overlay">
    <div class="modal-content">
      <h3>Confirm Selection</h3>
      <p class="confirmation-message">{{ confirmationMessage }}</p>
      <div class="confirmation-buttons">
        <button @click="confirmSelection" class="btn btn-success">Confirm</button>
        <button @click="cancelSelection" class="btn btn-danger">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import GameOverView from './GameOverView.vue';
import BombAnimation from '../components/BombAnimation.vue';
import SmallBombAnimation from '../components/SmallBombAnimation.vue';
import FireworksAnimation from '../components/FireworksAnimation.vue';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase';
import { getDoc, doc, collection, query, where, getDocs, setDoc, orderBy, limit, updateDoc, increment, arrayUnion, runTransaction } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import { startOfWeek, endOfWeek, subDays, parse } from 'date-fns';
import { formatInTimeZone, format } from 'date-fns-tz';

export default {
  components: {
    GameOverView,
    BombAnimation,
    SmallBombAnimation,
    FireworksAnimation,
  },
  setup() {
    const router = useRouter();
    const showFirstGameModal = ref(false);
    const showMenu = ref(false);
    const isAdmin = ref(false);
    const showLastWeekModal = ref(false);

    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    const goToLeaderboard = () => {
      router.push('/weekly-leaderboard');
      showMenu.value = false;
    };

    const goToAdmin = () => {
      router.push('/admin');
      showMenu.value = false;
    };

    const logout = async () => {
      try {
        await signOut(auth);
        router.push('/');
      } catch (error) {
        console.error('Error logging out:', error);
      }
      showMenu.value = false;
    };

    const checkFirstGame = async () => {
      const user = auth.currentUser;
      if (user) {
        const scoresRef = collection(db, `users/${user.uid}/scores`);
        const scoreSnapshot = await getDocs(scoresRef);
        if (scoreSnapshot.empty) {
          showFirstGameModal.value = true;
        }
      }
    };

    const closeFirstGameModal = () => {
      showFirstGameModal.value = false;
    };

    const checkAdminStatus = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          isAdmin.value = userData.roles && userData.roles.includes('admin');
        }
      }
    };

    onMounted(() => {
      checkFirstGame();
      checkAdminStatus();
    });

   

    const showLoginRegister = () => {
      router.push('/login');
    };

    return {
      showFirstGameModal,
      closeFirstGameModal,
      showMenu,
      toggleMenu,
      goToLeaderboard,
      goToAdmin,
      logout,
      isAdmin,
      showLoginRegister,
      showLastWeekModal,
    };
  },
  data() {
    return {
      user: null,
      isPlayer: false,
      game: null,
      shuffledAnswers: [],
      currentScore: 0,
      bombCount: 0,
      gameOver: false,
      currentQuestionValue: 0,
      holdDuration: 500, // Reduced from 1000 to 500 milliseconds
      holdInterval: null,
      bankHoldProgress: 0,
      bankHoldInterval: null,
      bankHoldDuration: 500, // Reduced from 1000 to 500 milliseconds
      isScrolling: false,
      touchStartY: 0,
      scrollThreshold: 2,
      alreadyPlayedToday: false,
      showGameOverModal: false,
      showBombAnimation: false,
      anonymousId: null,
      lastWeekUserRank: null,
      lastWeekTop3: [],
      showFireworks: false,
      showConfirmationModal: false,
      confirmationMessage: '',
      selectedAnswerIndex: null,
      gameInitialized: false, // Add a flag to track game initialization
      gameProgressExists: false, // Add a flag to track if game progress exists
      countdownToMidnight: '', // Add a property to store the countdown string
      disableAllAnswers: false,
      lastWeekModalShown: false,
      isLoading: true,
    };
  },
  async created() {
    this.updateCountdown();
    setInterval(this.updateCountdown, 1000); // Update the countdown every second
    onAuthStateChanged(auth, async (user) => {
        this.user = user;
        this.isPlayer = true;

        if (user) {
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            if (!userDoc.exists()) {
                await setDoc(doc(db, 'users', user.uid), {
                    email: user.email,
                    roles: ['player'],
                    username: user.displayName || 'Player',
                    createdAt: new Date().toISOString()
                });
            }
        }

        // Fetch today's game
        await this.fetchTodayGame();

        // Ensure game is fetched before checking progress
        if (this.game && this.user) {
            const progressRef = doc(db, `users/${this.user.uid}/gameProgress/${this.game.id}`);
            const progressDoc = await getDoc(progressRef);
            if (progressDoc.exists()) {
                await this.loadGameProgress(); // Load progress
                this.gameProgressExists = true; // Set the flag to true if progress exists
            } else {
            this.initializeGame(); // Initialize new game if no progress
            }
        }

        await this.checkIfAlreadyPlayed();
        this.gameInitialized = true; // Set the flag to true after initialization
    });

    const timeZone = 'America/New_York';
    const now = new Date();
    const lastShownDate = localStorage.getItem('lastWeekModalLastShown');

    // Format current time in New York timezone
    const nowNY = formatInTimeZone(now, timeZone, "yyyy-MM-dd'T'HH:mm:ssXXX");
    const nowNYDate = parse(nowNY, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date());

    if ((nowNYDate.getDay() === 1 || nowNYDate.getDay() === 2) && // If Monday or Tuesday ET
        (!lastShownDate || // Never shown before
         startOfWeek(new Date(lastShownDate)) < startOfWeek(nowNYDate))) { // Different week
        
        await this.fetchLastWeekLeaderboard();
        this.showLastWeekModal = true;
        localStorage.setItem('lastWeekModalLastShown', nowNY);
    }
  },
  methods: {
    updateCountdown() {
      const now = new Date();
      const timeZone = 'America/New_York';
      const midnight = new Date(now.toLocaleString('en-US', { timeZone }));
      midnight.setHours(24, 0, 0, 0); // Set to midnight

      const diff = midnight - now;
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      this.countdownToMidnight = `${hours}h ${minutes}m ${seconds}s`;
    },
    async saveGameProgress() {
        if (this.user && this.game) {
            const progressRef = doc(db, `users/${this.user.uid}/gameProgress/${this.game.id}`);
            await setDoc(progressRef, {
                currentScore: this.currentScore,
                bombCount: this.bombCount,
                shuffledAnswers: this.shuffledAnswers,
                date: new Date().toISOString()
            });
        }
    },

    async loadGameProgress() {
    if (this.user && this.game) {
        const progressRef = doc(db, `users/${this.user.uid}/gameProgress/${this.game.id}`);
        const progressDoc = await getDoc(progressRef);
        if (progressDoc.exists()) {
            const progress = progressDoc.data();
            this.currentScore = progress.currentScore;
            this.bombCount = progress.bombCount;
            this.shuffledAnswers = progress.shuffledAnswers.map(answer => ({
                ...answer,
                holdProgress: 0,
                animationClass: '',
            }));
        } else {
            console.log('No progress found for this game.');
        }
    } else {
        console.error('User or game is not properly initialized.');
    }
},
    onTouchStart(event) {
      this.touchStartY = event.touches[0].clientY;
      this.isScrolling = false;
    },

    onTouchMove(event) {
        const touchCurrentY = event.touches[0].clientY;
        const distanceMoved = Math.abs(touchCurrentY - this.touchStartY);

        if (distanceMoved > this.scrollThreshold) {
            this.isScrolling = true;
            this.cancelHold(this.selectedAnswerIndex); // Cancel the hold if scrolling
        }
    },

    startHold(index) {
        if (this.shuffledAnswers[index].clicked || this.isScrolling || this.disableAllAnswers) return;
        
        this.selectedAnswerIndex = index;
        this.shuffledAnswers[index].holdProgress = 0;
        
        clearInterval(this.holdInterval);

        this.holdInterval = setInterval(() => {
            this.shuffledAnswers[index].holdProgress += 100 / (this.holdDuration / 100);

            if (this.shuffledAnswers[index].holdProgress >= 100) {
                clearInterval(this.holdInterval);
                this.showConfirmationForAnswer(index);
            }
        }, 100);
    },

    showConfirmationForAnswer(index) {
      this.selectedAnswerIndex = index;
      this.confirmationMessage = `${this.shuffledAnswers[index].text}`;
      this.showConfirmationModal = true;
    },

    cancelHold(index) {
        clearInterval(this.holdInterval);

        if (index !== null && this.shuffledAnswers[index].holdProgress < 100) {
            this.shuffledAnswers[index].holdProgress = 0;
        }
    },

    async handleAnswerClick(answer) {
    if (!answer.clicked) {
        answer.clicked = true;
        answer.holdProgress = 0;

        if (answer.correct) {
            answer.displayValue = this.currentQuestionValue;
            answer.display = `${answer.displayValue} points`;
            answer.animationClass = 'correct-animation';
            this.currentScore += Number(answer.displayValue);
        } else {
            // Increment bomb count in Firestore
            try {
                const analyticsRef = doc(db, 'analytics', this.game.id);
                await updateDoc(analyticsRef, {
                    [`bombCounts.${answer.text}`]: increment(1)
                });
            } catch (error) {
                console.error('Error updating bomb count:', error);
            }

            answer.display = '';
            answer.showBombAnimation = true;
            this.bombCount++;
            if (this.bombCount === 3) {
                this.currentScore = 0;
                this.showBombAnimation = true;
                setTimeout(() => {
                    this.showBombAnimation = false;
                    this.endGame('bombs');
                }, 2500);
            } else {
                this.reducePoints();
            }
            answer.animationClass = 'bomb-animation';
        }

        this.updateCurrentScore();

        setTimeout(() => {
            answer.animationClass = '';
        }, 500);

        if (this.allCorrectAnswersFound()) {
            setTimeout(() => {
                this.endGame('allCorrect');
            }, 3500); // 3.5 second delay for fireworks
        }

        // Force reactivity by reassigning the array
        this.shuffledAnswers = [...this.shuffledAnswers];
        await this.saveGameProgress();  // Save the progress to Firestore
    }
},

    allCorrectAnswersFound() {
      const allCorrect = this.shuffledAnswers.filter(a => a.correct && a.clicked).length === this.game.correctAnswers.length;
      if (allCorrect && this.bombCount === 0) {
        this.showFireworks = true; // Only trigger fireworks if no bombs were hit
      }
      return allCorrect;
    },

    reducePoints() {
      this.currentQuestionValue = Math.floor(this.currentQuestionValue / 2);

      this.shuffledAnswers.forEach(answer => {
        if (answer.correct && answer.clicked) {
          answer.displayValue = this.currentQuestionValue;
          answer.display = `${answer.displayValue} points`;
        }
      });

      this.updateCurrentScore();
    },

    updateCurrentScore() {
      this.currentScore = this.shuffledAnswers.reduce((total, answer) => {
        if (answer.correct && answer.clicked) {
          return Number(total) + Number(answer.displayValue);
        }
        return total;
      }, 0);
    },

    initializeGame() {
    if (this.game) {
        // Only initialize if no shuffledAnswers exist (i.e., it's a fresh start)
        if (!this.shuffledAnswers.length) {
            this.shuffledAnswers = [
                ...this.game.correctAnswers.map(a => ({ text: a, correct: true, clicked: false, display: '', displayValue: 0, holdProgress: 0 })),
                ...this.game.incorrectAnswers.map(a => ({ text: a, correct: false, clicked: false, display: '', displayValue: 0, holdProgress: 0 }))
            ];

            this.shuffledAnswers = this.shuffledAnswers.sort(() => Math.random() - 0.5);

            this.currentScore = 0;
            this.currentQuestionValue = this.game.basePoints;
            this.bombCount = 0;
            this.gameOver = false;
        }
    }
},

    async fetchTodayGame() {
      this.isLoading = true;
      try {
        const timeZone = 'America/New_York';
        const today = new Date();

        // Calculate New York midnight in UTC
        const todayNY = new Date(today.toLocaleString('en-US', { timeZone }));
        todayNY.setHours(0, 0, 0, 0); // Set to midnight New York time

        const todayUTC = new Date(todayNY.getTime() + todayNY.getTimezoneOffset() * 60000);

        const tomorrowUTC = new Date(todayUTC);
        tomorrowUTC.setUTCDate(tomorrowUTC.getUTCDate() + 1);

        const gamesRef = collection(db, 'games');
        const q = query(
          gamesRef,
          where('liveDate', '>=', todayUTC),
          where('liveDate', '<', tomorrowUTC),
          orderBy('liveDate', 'desc'),
          limit(1)
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const gameDoc = querySnapshot.docs[0];
          this.game = { id: gameDoc.id, ...gameDoc.data() };
          this.setupGame();
        } else {
          this.game = null;
          console.log('No game available for today');
        }
      } catch (error) {
        this.game = null;
      } finally {
        this.isLoading = false;
      }
    },

    showConfirmationForBank() {
      this.disableAllAnswers = true;
      this.confirmationMessage = `Stop and bank ${this.currentScore} points?`;
      this.showConfirmationModal = true;
      this.selectedAnswerIndex = null;
    },

    async bankPoints() {
      if (this.currentScore > 0) {
        if (this.user) {
          try {
            await this.saveScore();
            await this.updateWeeklyLeaderboard(this.currentScore);
            this.endGame('banked');
          } catch (error) {
            alert("Failed to bank points: " + error.message);
          }
        } else {
          // Handle anonymous user
          this.saveAnonymousScore();
          this.endGame('banked');
        }
      } else {
        console.log('Unable to bank points');
      }
    },

    async updateDailyAnalytics(reason) {
  const gameId = this.game.id; // Use the gameId for the document name
  const analyticsRef = doc(db, 'analytics', gameId);

  try {
    await updateDoc(analyticsRef, {
      count: increment(1),
      [reason]: increment(1) // Increment the count for the specific reason
    });
  } catch (error) {
    if (error.code === 'not-found') {
      // If the document does not exist, create it
      try {
        await setDoc(analyticsRef, { 
          count: 1,
          banked: reason === 'banked' ? 1 : 0,
          bombs: reason === 'bombs' ? 1 : 0,
          allCorrect: reason === 'allCorrect' ? 1 : 0
        });
        
      } catch (createError) {
        console.error('Error creating daily analytics document:');
      }
    } else {
      console.error('Error updating daily analytics:');
    }
  }
},

    async endGame(reason) {
      this.gameOver = true;

      // Update daily analytics count with reason
      await this.updateDailyAnalytics(reason);

      if (reason === 'bombs') {
        this.currentScore = 0;
      }

      if (this.user) {
        try {
          const scoreRef = doc(db, `users/${this.user.uid}/scores/${this.game.id}`);
          const scoreDoc = await getDoc(scoreRef);

          if (!scoreDoc.exists() || this.isAdmin) {
            await this.saveScore();
            await this.updateWeeklyLeaderboard(this.currentScore);
          } else {
            console.log('User has already played this game');
          }
        } catch (error) {
          alert("Failed to end game: " + error.message);
        }
      } else {
        // Handle anonymous user
        this.saveAnonymousScore();
      }
    },

    async saveScore() {
    const user = auth.currentUser;
    if (user && this.game) {
      const scoreRef = doc(db, `users/${user.uid}/scores/${this.game.id}`);
      await setDoc(scoreRef, {
        gameId: this.game.id,
        score: this.currentScore,
        bombsHit: this.bombCount,
        bankedPoints: this.currentScore > 0 && this.bombCount < 3,
        date: new Date().toISOString()
      });
    }
  },

  async updateWeeklyLeaderboard() {
  const user = auth.currentUser;
  if (!user) {
    console.error('No user logged in when trying to update weekly leaderboard');
    return;
  }

  const userDoc = await getDoc(doc(db, 'users', user.uid));
  const username = userDoc.exists() ? userDoc.data().username : 'Anonymous';
  const score = this.currentScore;

  // Get current time in New York timezone
  const timeZone = 'America/New_York';
  const nowNY = new Date().toLocaleString('en-US', { timeZone });
  const nyDate = new Date(nowNY);

  // Find the most recent Monday at midnight ET
  let startOfWeekNY = new Date(nyDate);
  startOfWeekNY.setDate(nyDate.getDate() - ((nyDate.getDay() + 6) % 7));
  startOfWeekNY.setHours(0, 0, 0, 0);

  const leaderboardId = format(startOfWeekNY, 'yyyy-MM-dd');
  const weeklyLeaderboardRef = doc(db, 'weeklyLeaderboard', leaderboardId);

  const gameData = {
    gameId: this.game.id,
    score: score,
    bombsHit: this.bombCount,
    questionText: this.game.questionText  // Add questionText
  };

  try {
    await runTransaction(db, async (transaction) => {
      const weeklyLeaderboardDoc = await transaction.get(weeklyLeaderboardRef);
      
      if (!weeklyLeaderboardDoc.exists()) {
        // Create new document
        transaction.set(weeklyLeaderboardRef, {
          startDate: formatInTimeZone(startOfWeekNY, timeZone, "yyyy-MM-dd'T'HH:mm:ssXXX"),
          endDate: formatInTimeZone(endOfWeek(startOfWeekNY, { weekStartsOn: 1 }), timeZone, "yyyy-MM-dd'T'HH:mm:ssXXX"),
          scores: {
            [user.uid]: {
              uid: user.uid,
              username: username,
              weeklyScore: score,
              games: [gameData]  // Use gameData object
            }
          }
        });
      } else {
        // Update existing document
        const data = weeklyLeaderboardDoc.data();
        const userScores = data.scores && data.scores[user.uid];
        
        // Check if user has already played this game
        const alreadyPlayed = userScores && userScores.games && 
          userScores.games.some(game => game.gameId === this.game.id);
        
        if (alreadyPlayed) {
          console.log('User has already played this game. Not updating weekly score.');
          return;
        }
        
        // Update the document
        transaction.update(weeklyLeaderboardRef, {
          [`scores.${user.uid}.weeklyScore`]: increment(score),
          [`scores.${user.uid}.username`]: username,
          [`scores.${user.uid}.uid`]: user.uid,
          [`scores.${user.uid}.games`]: arrayUnion(gameData)  // Use gameData object
        });
      }
    });

    console.log('Weekly leaderboard updated successfully');
  } catch (error) {
    console.error('Error updating weekly leaderboard:', error);
  }
},


    allBombsRemaining() {
      return this.shuffledAnswers.every(answer => answer.clicked || !answer.correct);
    },

    navigateToLeaderboard() {
      this.$router.push('/weekly-leaderboard');
    },

    setupGame() {
    if (this.shuffledAnswers.length === 0) {
        this.initializeGame();
    }
},

    closeGameOverModal() {
      this.showGameOverModal = false;
    },

    async checkIfAlreadyPlayed() {
  if (!this.game) {
    console.error('Game data is not available');
    return;
  }

  if (this.user) {
    // Code for logged-in users
    const scoreRef = doc(db, `users/${this.user.uid}/scores/${this.game.id}`);
    const scoreDoc = await getDoc(scoreRef);
    this.alreadyPlayedToday = scoreDoc.exists();
    if (this.alreadyPlayedToday) {
      console.log('User has already played today');
    }
  } else {
    // Code for anonymous users
    const anonymousScore = localStorage.getItem('anonymousScore');
    if (anonymousScore) {
      const parsedScore = JSON.parse(anonymousScore);
      this.alreadyPlayedToday = parsedScore.gameId === this.game.id;
      if (this.alreadyPlayedToday) {
        console.log('Anonymous user has already played today');
      }
    } else {
      this.alreadyPlayedToday = false;
    }
  }
},

    async saveAnonymousScore() {
      localStorage.setItem('anonymousScore', JSON.stringify({
        score: this.currentScore,
        gameId: this.game.id,
        bombsHit: this.bombCount,
        date: new Date().toISOString()
      }));
    },

    async fetchLastWeekLeaderboard() {
      const timeZone = 'America/New_York';
      const now = new Date();

      // Calculate the start of the previous week
      let startOfWeekNY = startOfWeek(now, { weekStartsOn: 1 });
      startOfWeekNY = subDays(startOfWeekNY, 7);
      startOfWeekNY.setHours(0, 0, 0, 0);

      // Format the date for the Firestore document ID
      const leaderboardId = formatInTimeZone(startOfWeekNY, timeZone, 'yyyy-MM-dd');

      const leaderboardRef = doc(db, 'weeklyLeaderboard', leaderboardId);
      const leaderboardDoc = await getDoc(leaderboardRef);

      if (leaderboardDoc.exists()) {
        const leaderboardData = leaderboardDoc.data();
        if (leaderboardData && leaderboardData.scores) {
          const scoresArray = Object.entries(leaderboardData.scores).map(([uid, data]) => ({
            uid,
            ...data
          }));

          const sortedPlayers = scoresArray.sort((a, b) => b.weeklyScore - a.weeklyScore);

          this.lastWeekTop3 = sortedPlayers.slice(0, 3);

          if (this.user) {
            const userScore = sortedPlayers.find(player => player.uid === this.user.uid);
            this.lastWeekUserRank = userScore ? sortedPlayers.indexOf(userScore) + 1 : null;
          }
        }
      }
    },

    getMedalIcon(index) {
      const icons = ['fa-medal', 'fa-medal', 'fa-medal'];
      return icons[index];
    },

    shareGame() {
      const message = "Get7 - the push-your-luck trivia game";
      const url = "https://get7game.com";
      if (navigator.share) {
        navigator.share({
          title: 'Get7 Game',
          text: message,
          url: url
        }).catch(console.error);
      } else {
        alert('Sharing is not supported in this browser.');
      }
    },

    closeLastWeekModal() {
      this.showLastWeekModal = false;
    },

    triggerFireworks() {
      this.showFireworks = true;
    },

    confirmSelection() {
      if (this.disableAllAnswers) {
        // This is a bank confirmation
        this.bankPoints();
      } else if (this.selectedAnswerIndex !== null) {
        // This is an answer confirmation
        this.handleAnswerClick(this.shuffledAnswers[this.selectedAnswerIndex]);
        this.selectedAnswerIndex = null;
      }
      this.showConfirmationModal = false;
    },

    cancelSelection() {
      this.showConfirmationModal = false;
      this.selectedAnswerIndex = null;
      this.bankHoldProgress = 0;
      this.shuffledAnswers.forEach(answer => answer.holdProgress = 0);
      this.disableAllAnswers = false;
    },

    goToProfile() {
      this.$router.push('/profile');
    },

    shareScore() {
      const gameDate = format(new Date(), 'MMMM d');
      const bombsHitText = this.bombCount > 0 ? '💣'.repeat(this.bombCount) : '0️⃣';
      const message = `Get7 - ${gameDate}\nCategory: ${this.game?.questionText || ''}\nScore: ${this.currentScore}\nBombs Hit: ${bombsHitText}`;

      if (navigator.share) {
        try {
          navigator.share({
            title: 'Get7 Game Score',
            text: message,
            url: 'https://get7game.com'
          });

          // Use a transaction to safely increment or initialize timesShared
          const statsRef = doc(db, 'analytics', this.game.id);
          runTransaction(db, async (transaction) => {
            const statsDoc = await transaction.get(statsRef);
            if (!statsDoc.exists()) {
              transaction.set(statsRef, { timesShared: 1 });
            } else {
              const currentTimesShared = statsDoc.data().timesShared || 0;
              transaction.update(statsRef, { timesShared: currentTimesShared + 1 });
            }
          });

        } catch (error) {
          console.error('Error sharing or updating share count');
        }
      } else {
        // Fallback for browsers that do not support the Web Share API
        alert('Sharing is not supported in this browser. Please copy the text manually: ' + message);
      }
    },

  },
  mounted() {
    this.shuffledAnswers.forEach(answer => {
      answer.holdProgress = 0;
    });

  },
  watch: {
  game(newGame) {
    if (newGame && this.shuffledAnswers.length === 0) {
      // Only set up the game if it's a new game and shuffledAnswers are not loaded yet
      this.setupGame();
    }
  },
  currentScore() {
    if (this.gameInitialized) {
      this.saveGameProgress();
    }
  },
  bombCount() {
    if (this.gameInitialized) {
      this.saveGameProgress();
    }
  },
  shuffledAnswers: {
    handler(newAnswers) {
      if (newAnswers.length > 0 && this.gameInitialized) {
        this.saveGameProgress();
      }
    },
    deep: true
  }
},
  computed: {
    feedbackEmailLink() {
      const email = 'get7game@gmail.com';
      const subject = encodeURIComponent(`Get7Game - ${this.game?.questionText || ''}`);
      return `mailto:${email}?subject=${subject}`;
    }
  },
};
</script>

<style scoped>
.play-game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

/* Apply the background only on screens wider than 768px (typical tablet/desktop) */
@media (min-width: 768px) {
  .play-game-container {
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  }
}

.already-played-message {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.already-played-message h2 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

.already-played-message p {
  font-size: 1.2em;
  color: #555;
}

.play-game-content {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  padding: 40px;
  width: 100%;
  max-width: 600px;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fireworks-btn {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ff5722;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.fireworks-btn:hover {
  background-color: #e64a19;
}

h1 {
  font-size: 36px;
  color: white;
  margin-bottom: 10px;
  text-align: center;
}

.btn {
  padding: 10px 20px;
  margin: 10px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bank-btn {
  background-color: #28a745;
  color: white;
  font-weight: bold;
  padding: 14px 28px;
  margin-top: 15px;
  font-size: 16.875px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bank-btn:hover:not(:disabled) {
  background-color: #218838;
}

.bank-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.answers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
}

.answer-wrapper {
  width: calc(50% - 10px);
  margin-bottom: 20px;
}

.answer-btn {
  position: relative;
  width: 100%;
  height: 80px;
  padding: 10px;
  font-size: clamp(1.2em, 1.5vw, 1.8em);
  cursor: pointer;
  background-color: #667eea;
  color: white;
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px #490469;
  transition: transform 0.1s ease, box-shadow 0.1s ease, background-color 0.3s ease;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.answer-btn:active {
  transform: translateY(4px);
  box-shadow: 0 0 #003a75;
}

.fill-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  transition: width 0.1s linear;
  pointer-events: none;
}

.answer-btn.correct {
  background-color: #28a745;
  box-shadow: 0 4px #1e7e34;
}

.answer-btn.bomb {
  background-color: #dc3545;
  box-shadow: 0 4px #bd2130;
}

.answer-btn[disabled] {
  cursor: default;
  transform: none;
}

.answer-text {
  transition: transform 0.3s ease;
}

.answer-btn.correct .answer-text,
.answer-btn.bomb .answer-text {
  transform: translateY(-10px);
  font-size: 0.9em;
}

.answer-result {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  font-size: 0.9em;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.answer-btn.correct .answer-result,
.answer-btn.bomb .answer-result {
  opacity: 1;
}

.bomb-icons {
  font-size: 24px;
  margin-bottom: 5px;
}

.bomb-icons i {
  margin: 0 5px;
  transition: all 0.3s ease;
}

.bomb-icons .exploded {
  color: #dc3545;
  transform: scale(1.2);
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.bomb-icons .exploded {
  animation: shake 0.5s;
  animation-iteration-count: 1;
}

.correct-animation {
  animation: pulse 0.5s;
}


.bomb-animation {
  animation: shake 0.5s;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.instruction {
  margin-top: 10px;
  font-style: italic;
  color: #6c757d;
}

.confirmation-message {
  font-size: 1.5em;
  color: #000000;
}

.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #aaa;
  font-size: 34px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
}

.medal-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.gold-medal {
  background-color: gold;
  border-radius: 50%;
}

.silver-medal {
  background-color: silver;
  border-radius: 50%;
}

.bronze-medal {
  background-color: #cd7f32;
  border-radius: 50%;
}

.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

.rules-btn {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  font-weight: bold;
  padding: 10px 15px;
  margin: 5px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  white-space: nowrap;
  flex: 1;
  min-width: 0;
  display: inline-block; /* Ensure buttons are inline */
  text-align: center; /* Center text */
}

.rules-btn:hover {
  background: linear-gradient(135deg, #5a6fd6 0%, #6a4292 100%);
}

@media (max-width: 480px) {
  .rules-btn {
    font-size: 12px;
    padding: 8px 12px;
  }
}

.question-card {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;/* Add this to position the feedback link */
  text-align: center; /* Center all text and inline elements */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
}

.question-card h3 {
  color: #ffffff;
  margin-top: 0;
  font-size: 1.2em;
}

.question-card h4 {
  color: #ffffff;
  margin-top: 0;
  font-size: 1em;
}

.question-card h2 {
  color: #ffffff;
  margin: 10px 0;
  font-size: clamp(1.5em, 4vw, 3em); /* Adjusts font size based on viewport width */
  white-space: normal; /* Prevents text from wrapping to the next line */
  overflow: break-word; /* Ensures text does not overflow the container */
}

.question-card .question-short-description {
  color: #f0f0f0;
  font-size: 1.4em;
  margin-bottom: 20px;
}

.bank-btn {
  margin-top: auto;
  align-self: center;
  background-color: #28a745;
  color: white;
  font-weight: bold;
  padding: 14px 28px;
  font-size: 16.875px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden;
}

.top-finishers {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.finisher-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.finisher-row i {
  font-size: 24px;
  margin-right: 10px;
}

.finisher-row:nth-child(1) i {
  color: gold;
}

.finisher-row:nth-child(2) i {
  color: silver;
}

.finisher-row:nth-child(3) i {
  color: #cd7f32; /* bronze */
}

.player-name {
  font-size: 16px;
}

.feedback-link {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 0.9em;
  color: #f0f0f0;
  text-decoration: none;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.feedback-link:hover {
  opacity: 1;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.confirmation-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirmation-buttons .btn-success {
  background-color: #28a745;
  color: white;
}

.confirmation-buttons .btn-danger {
  background-color: #dc3545;
  color: white;
}

.game-over-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-green-btn {
  background-color: #28a745; /* Green color */
  color: white;
  font-size: 28px; /* Smaller font size */
  padding: 8px 12px; /* Smaller padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-width: 300px; /* Set a maximum width */
  width: auto; /* Allow the button to size based on content */
  display: inline-block; /* Prevent the button from stretching */
  text-align: center; /* Center the text */
  margin: 0 auto; /* Center the button */
}

.small-green-btn:hover {
  background-color: #218838; /* Darker green on hover */
}

a img {
  display: inline-block; /* Align with buttons */
  vertical-align: middle; /* Align vertically with text */
  margin: 5px; /* Add consistent margin */
}

.rules-btn--large {
  font-size: 115%;
  padding: 12px 18px;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  font-weight: bold;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  white-space: nowrap;
  flex: 1;
  min-width: 0;
  display: inline-block; /* Ensure buttons are inline */
  text-align: center; /* Center text */
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.loading-text {
  margin-top: 20px;
  font-size: 1.2em;
  color: #667eea;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #764ba2;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>













