<template>
  <div class="weekly-leaderboard-container">
    <div class="leaderboard-box">
      <!-- Add tabs for switching views -->
      <div class="tabs">
        <button
          :class="{ active: currentView === 'leaderboard' }"
          @click="currentView = 'leaderboard'"
        >
          Global Weekly Leaderboard
        </button>
        <button
          :class="{ active: currentView === 'squads' }"
          @click="currentView = 'squads'"
        >
          My Squads
        </button>
      </div>

      <!-- Conditionally render views based on the selected tab -->
      <div v-if="currentView === 'leaderboard'">
        
        <div class="navigation-buttons">
          <button @click="goToHome" class="nav-button">Home</button>
          <button v-if="hasPlayedToday" @click="showTodayStats" class="nav-button">Today's Stats</button>
        </div>
        
        <!-- Add loading spinner -->
        <div v-if="isLoading" class="loading-spinner">
          <div class="spinner"></div>
        </div>
        
        <!-- Only show content when not loading -->
        <template v-else>
          <h3>For the week of {{ formatDate(currentWeekStart) }}</h3>
          <div v-if="userRank" class="user-rank">
            <h3 @click="showCurrentUserScores" class="clickable">My Rank: {{ userRank }}</h3>
            <p>Score: {{ userScore }}</p>
          </div>
          <div class="leaderboard-list">
            <h2>Top 25</h2>
            <div v-for="(player) in topPlayers" :key="player.uid" class="player-row">
              <div class="rank">{{ player.rank }}</div>
              <div class="player-info">
                <span class="username" @click="showUserScores(player)">{{ player.username }}</span>
              </div>
              <div class="score">{{ player.weeklyScore }}</div>
            </div>
          </div>
        </template>

        <!-- Move modals outside of v-else template -->
        <div v-if="showModal" class="modal">
          <div class="modal-content">
            <span class="close" @click="closeModal">&times;</span>
            <h3>{{ selectedUser.username }}'s Scores</h3>
            <!-- Add Trophy Case -->
          <div v-if="hasTrophies" class="trophy-case">
            <h4>Trophy Case</h4>
            <div class="medals">
              <span v-for="n in trophies.firstPlaceFinishes" :key="'gold'+n" class="medal gold">
                <i class="fas fa-medal"></i>
              </span>
              <span v-for="n in trophies.secondPlaceFinishes" :key="'silver'+n" class="medal silver">
                <i class="fas fa-medal"></i>
              </span>
              <span v-for="n in trophies.thirdPlaceFinishes" :key="'bronze'+n" class="medal bronze">
                <i class="fas fa-medal"></i>
              </span>
            </div>
          </div>
            <table>
              <thead>
                <tr>
                  <th>Score</th>
                  <th>Bombs Hit</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="score in userScores" :key="score.questionText">
                  <tr class="game-row">
                    <td>{{ score.score }}</td>
                    <td>
                      <span v-for="n in score.bombsHit" :key="n" class="bomb-icon">💣</span>
                    </td>
                  </tr>
                  <tr class="question-row">
                    <td colspan="2" class="question-text">{{ score.questionText }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
            <button class="close-button" @click="closeModal">Close</button>
          </div>
        </div>

        <div v-if="showStatsModal" class="modal">
          <div class="modal-content">
            <span class="close" @click="closeStatsModal">&times;</span>
            <h3>Today's Community Stats</h3>
            <div v-if="todayStats">
              <p>Got7: {{ calculatePercentage(todayStats.allCorrect, todayStats.count) }}%</p>
              <p>Banked Points: {{ calculatePercentage(todayStats.banked, todayStats.count) }}%</p>
              <p>
                <span v-for="n in 3" :key="n" class="bomb-icon">💣</span>
                : {{ calculatePercentage(todayStats.bombs, todayStats.count) }}%
              </p>
              <p v-if="mostCommonBombs.length">
                Most Common Bomb(s): <span class="bold-text">{{ mostCommonBombs.join(', ') }}</span>
              </p>
            </div>
            <div v-else>
              <p>No stats available for today.</p>
            </div>
            <button class="close-button" @click="closeStatsModal">Close</button>
          </div>
        </div>
      </div>

      <div v-if="currentView === 'squads'">
        <SquadView />
      </div>
    </div>
  </div>
</template>

<script>
import { formatInTimeZone } from 'date-fns-tz';
import { subDays, addDays, endOfWeek, startOfDay } from 'date-fns';
import { db, auth } from '../firebase';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import SquadView from './SquadView.vue'; // Import the SquadView component

export default {
  name: 'WeeklyLeaderboardView',
  components: {
    SquadView, // Register the SquadView component
  },
  data() {
    return {
      leaderboardData: null,
      topPlayers: [],
      userRank: null,
      userScore: 0,
      currentWeekStart: null,
      showModal: false,
      selectedUser: null,
      userScores: [],
      gameInfo: {}, // Store game information here
      totalPlayers: 0,
      showStatsModal: false,
      todayStats: null,
      hasPlayedToday: false,
      todayGameId: null, // Add this to store today's gameId
      currentView: 'leaderboard', // Track the current view
      trophies: {
        firstPlaceFinishes: 0,
        secondPlaceFinishes: 0,
        thirdPlaceFinishes: 0,
      },
      mostCommonBombs: [],
      isLoading: true,
    };
  },
  mounted() {
    this.fetchLeaderboardData();
    this.fetchGameIdsForCurrentWeek(); // Fetch game information once
    this.checkIfPlayedToday();
  },
  computed: {
    hasTrophies() {
      return this.trophies.firstPlaceFinishes > 0 ||
             this.trophies.secondPlaceFinishes > 0 ||
             this.trophies.thirdPlaceFinishes > 0;
    },
  },
  methods: {
    async fetchLeaderboardData() {
      this.isLoading = true;
      try {
        // Get all documents from weeklyLeaderboard collection
        const leaderboardRef = collection(db, 'weeklyLeaderboard');
        const leaderboardSnapshot = await getDocs(leaderboardRef);
        
        // Find the document with the most recent date
        let mostRecentDoc = null;
        let mostRecentDate = null;
        
        leaderboardSnapshot.forEach((doc) => {
          const docDate = new Date(doc.id);
          if (!mostRecentDate || docDate > mostRecentDate) {
            mostRecentDate = docDate;
            mostRecentDoc = doc;
          }
        });

        if (mostRecentDoc) {
          this.leaderboardData = mostRecentDoc.data();
          this.currentWeekStart = mostRecentDoc.id;
          
          if (this.leaderboardData && this.leaderboardData.scores) {
            const scoresArray = Array.isArray(this.leaderboardData.scores) 
              ? this.leaderboardData.scores 
              : Object.entries(this.leaderboardData.scores).map(([uid, data]) => ({
                  uid,
                  ...data
                }));

            // Fetch all usernames
            const usernames = await this.fetchUsernames(scoresArray.map(score => score.uid));

            // Sort players by score and assign ranks
            const sortedPlayers = scoresArray
              .map(player => ({
                ...player,
                username: usernames[player.uid] || player.username || 'Anonymous'
              }))
              .sort((a, b) => b.weeklyScore - a.weeklyScore);

            this.totalPlayers = sortedPlayers.length;

            let currentRank = 1;
            let previousScore = null;

            this.topPlayers = sortedPlayers.slice(0, 25).map((player, index) => {
              if (player.weeklyScore !== previousScore) {
                currentRank = index + 1;
              }
              previousScore = player.weeklyScore;
              return { ...player, rank: currentRank };
            });

            // Calculate user rank and score
            const currentUser = auth.currentUser;
            if (currentUser) {
              const userScore = sortedPlayers.find(score => score.uid === currentUser.uid);
              if (userScore) {
                this.userScore = userScore.weeklyScore;
                this.userRank = sortedPlayers.findIndex(score => score.uid === currentUser.uid) + 1;
                
                // Adjust user rank for ties
                for (let i = 0; i < this.userRank - 1; i++) {
                  if (sortedPlayers[i].weeklyScore === this.userScore) {
                    this.userRank = i + 1;
                    break;
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchUsernames(uids) {
      const usernamesMap = {};
      const usersRef = collection(db, 'users');
      const userDocs = await getDocs(usersRef);
      
      userDocs.forEach(doc => {
        if (uids.includes(doc.id)) {
          usernamesMap[doc.id] = doc.data().username || 'Anonymous';
        }
      });
      
      return usernamesMap;
    },

    navigateToPreviousWeek() {
      if (this.currentWeekStart) {
        this.currentWeekStart = subDays(this.currentWeekStart, 7);
        this.fetchLeaderboardData();
      }
    },
    navigateToNextWeek() {
      if (this.currentWeekStart) {
        this.currentWeekStart = addDays(this.currentWeekStart, 7);
        this.fetchLeaderboardData();
      }
    },
    goToHome() {
      this.$router.push('/');
    },
    async fetchGameIdsForCurrentWeek() {
      // Convert string date to Date object if needed
      const startOfWeekNY = typeof this.currentWeekStart === 'string' 
        ? new Date(this.currentWeekStart) 
        : this.currentWeekStart;
        
      const endOfWeekNY = endOfWeek(startOfWeekNY, { weekStartsOn: 1 });
      endOfWeekNY.setHours(23, 59, 59, 999);

      // Add debug logs
      console.log('Date range for games query:', {
        start: startOfWeekNY,
        end: endOfWeekNY,
        currentWeekStart: this.currentWeekStart
      });

      // Ensure both dates are valid before querying
      if (isNaN(startOfWeekNY.getTime()) || isNaN(endOfWeekNY.getTime())) {
        console.error('Invalid date range');
        return [];
      }

      const gamesRef = collection(db, 'games');
      const gamesQuery = query(
        gamesRef,
        where('liveDate', '>=', startOfWeekNY),
        where('liveDate', '<=', endOfWeekNY)
      );
      
      const gamesSnapshot = await getDocs(gamesQuery);
      
      // Add debug log
      console.log('Query returned documents:', gamesSnapshot.size);

      const gameInfo = {};
      gamesSnapshot.forEach(doc => {
        const data = doc.data();
        gameInfo[doc.id] = {
          gameDate: data.liveDate,
          questionText: data.questionText,
        };
      });

      this.gameInfo = gameInfo;
      return Object.keys(gameInfo);
    },
    async showUserScores(user) {
      console.log('Showing scores for user:', user);
      
      this.selectedUser = user;
      
      // Get the user's games directly from the leaderboard data
      if (this.leaderboardData && this.leaderboardData.scores && this.leaderboardData.scores[user.uid]) {
        const userData = this.leaderboardData.scores[user.uid];
        
        // Map the games and handle missing data
        this.userScores = await Promise.all(userData.games.map(async game => {
          // If we have all the data in the leaderboard, use it
          if (game.questionText !== undefined && game.bombsHit !== undefined) {
            return {
              questionText: game.questionText,
              score: game.score,
              bombsHit: game.bombsHit
            };
          }
          
          // Get bombsHit from user's scores collection
          const scoreRef = doc(db, `users/${user.uid}/scores/${game.gameId}`);
          const scoreDoc = await getDoc(scoreRef);
          const bombsHit = scoreDoc.exists() ? (scoreDoc.data().bombsHit || 0) : 0;
          
          // Get questionText from games collection
          const gameRef = doc(db, 'games', game.gameId);
          const gameDoc = await getDoc(gameRef);
          const questionText = gameDoc.exists() ? gameDoc.data().questionText : 'Question not available';
          
          return {
            questionText,
            score: game.score,
            bombsHit
          };
        }));
      } else {
        this.userScores = [];
      }
      
      console.log('Fetched scores:', this.userScores);
      
      await this.fetchUserTrophies(user.uid);
      this.showModal = true;
    },
    async showCurrentUserScores() {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.error('No current user logged in');
        return;
      }

      try {
        // Fetch the current user's document to get the username
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          this.selectedUser = {
            uid: currentUser.uid,
            username: userData.username || 'Anonymous',
          };
          this.userScores = await this.fetchUserScores(this.selectedUser);
          this.showModal = true;
        } else {
          console.error('User document does not exist');
        }
      } catch (error) {
        console.error('Error fetching current user scores:', error);
      }
    },
    closeModal() {
      this.showModal = false;
      this.selectedUser = null;
      this.userScores = [];
    },
    formatDate(date) {
      // For the currentWeekStart (document ID), parse it directly
      if (typeof date === 'string' && date === this.currentWeekStart) {
        const months = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
        // Split the date string and convert to numbers, but skip the year
        const parts = date.split('-');
        const month = parseInt(parts[1], 10) - 1; // Convert to 0-based index
        const day = parseInt(parts[2], 10);
        return `${months[month]} ${day}`;
      }

      // For other dates (like game dates in the modal), use the existing logic
      if (date instanceof Object && date.seconds) {
        date = new Date(date.seconds * 1000);
      }

      if (!(date instanceof Date) || isNaN(date)) {
        return 'Invalid Date';
      }

      return formatInTimeZone(date, 'America/New_York', 'MMMM d');
    },
    async showTodayStats() {
      if (!this.todayGameId) {
        console.error('No gameId for today');
        return;
      }

      const statsRef = doc(db, 'analytics', this.todayGameId);
      const statsDoc = await getDoc(statsRef);

      if (statsDoc.exists()) {
        const data = statsDoc.data();
        this.todayStats = data;

        // Process bombCounts to find the most commonly selected bomb(s)
        if (data.bombCounts) {
          const bombCounts = data.bombCounts;
          const maxCount = Math.max(...Object.values(bombCounts));
          this.mostCommonBombs = Object.keys(bombCounts).filter(bomb => bombCounts[bomb] === maxCount);
        } else {
          this.mostCommonBombs = [];
        }
      } else {
        this.todayStats = null;
        this.mostCommonBombs = [];
      }

      this.showStatsModal = true;
    },

    closeStatsModal() {
      this.showStatsModal = false;
      this.todayStats = null;
    },

    calculatePercentage(value, total) {
      if (total === 0) return 0;
      return ((value / total) * 100).toFixed(1);
    },

    async checkIfPlayedToday() {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        this.hasPlayedToday = false;
        return;
      }

      const today = startOfDay(new Date());
      
      
      const gamesRef = collection(db, 'games');
      const gamesQuery = query(gamesRef, where('liveDate', '>=', today));
      const gamesSnapshot = await getDocs(gamesQuery);

      if (!gamesSnapshot.empty) {
        this.todayGameId = gamesSnapshot.docs[0].id; // Store the gameId
        const scoreRef = doc(db, `users/${currentUser.uid}/scores/${this.todayGameId}`);
        const scoreDoc = await getDoc(scoreRef);

        this.hasPlayedToday = scoreDoc.exists();
      } else {
        this.hasPlayedToday = false;
      }
    },
    async fetchUserTrophies(uid) {
      const userRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        this.trophies = {
          firstPlaceFinishes: userData.firstPlaceFinishes || 0,
          secondPlaceFinishes: userData.secondPlaceFinishes || 0,
          thirdPlaceFinishes: userData.thirdPlaceFinishes || 0,
        };
      } else {
        this.trophies = {
          firstPlaceFinishes: 0,
          secondPlaceFinishes: 0,
          thirdPlaceFinishes: 0,
        };
      }
    },
  }
};
</script>

<style scoped>
.weekly-leaderboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 20px;
}

.leaderboard-box {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tabs button {
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
  border: none;
  background-color: #f0f0f0;
  color: #333;
  font-weight: bold;
  position: relative; /* Add relative positioning */
}

.tabs button.active {
  background-color: #764ba2;
  color: #fff;
}

.tabs button:not(.active):hover {
  background-color: #e0e0e0;
}

.new-label {
  color: red;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 600px) {
  .new-label {
    position: static; /* Remove absolute positioning */
    display: block; /* Ensure it takes its own line */
    margin-left: 0; /* Reset margin */
    margin-top: 5px; /* Add some space above */
    text-align: center; /* Center the text */
  }
}

.tabs button:first-child {
  border-right: 1px solid #ccc;
}

.tabs button:last-child {
  border-left: 1px solid #ccc;
}

.nav-button {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-bottom: 10px;
  margin-left: 10px;
}

.nav-button:hover {
  background: linear-gradient(135deg, #764ba2 0%, #667eea 100%);
}

h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.user-rank {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.user-rank h3 {
  margin: 0;
  color: #333;
}

.user-rank p {
  margin: 5px 0 0;
  color: #666;
}

.leaderboard-list {
  max-height: 400px;
  overflow-y: auto;
}

.player-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.rank {
  font-weight: bold;
  width: 30px;
}

.player-info {
  flex-grow: 1;
  margin-left: 10px;
}

.username {
  font-weight: bold;
  cursor: pointer; /* Change cursor to pointer */
  color: #007BFF; /* Add a color to indicate it's clickable */
  text-decoration: underline; /* Underline the text */
}

.username:hover {
  color: #0056b3; /* Change color on hover */
  text-decoration: none; /* Remove underline on hover */
}

.score {
  font-weight: bold;
  color: #4CAF50;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: none; /* Remove border from all cells */
}

th {
  background-color: #f4f4f4;
}

td {
  background-color: #fff;
}

.game-row td {
  border-bottom: none; /* Remove border from game rows */
}

.question-row td {
  border-bottom: 1px solid #ddd; /* Add border to question rows */
}

.bomb-icon {
  font-size: 1.2em;
  margin-right: 2px;
}

.question-text {
  font-style: italic;
  color: #666;
  padding-top: 5px;
  padding-bottom: 10px;
}

.close-button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.close-button:hover {
  background-color: #45a049;
}

.clickable {
  cursor: pointer;
  color: #007BFF;
  text-decoration: underline;
}

.clickable:hover {
  color: #0056b3;
  text-decoration: none;
}
.trophy-case {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.medals {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.medal {
  font-size: 24px;
}

.gold {
  color: #FFD700;
}

.silver {
  color: #C0C0C0;
}

.bronze {
  color: #CD7F32;
}

.bold-text {
  font-weight: bold;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #764ba2;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>


